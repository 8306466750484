.panel-heading .btn-primary {
  padding: 2px 15px;
  margin-top: -3px; }

.clear {
  clear: both; }

.table-responsive .pagination {
  float: right; }

.table-actions {
  margin-bottom: 10px; }
  .table-actions form {
    display: inline-block; }
  .table-actions .form-inline .form-group {
    margin: 0 8px; }

#custom-tools-table img {
  max-width: 100px;
  max-height: 100px; }

#custom-tools-table .color-cell {
  text-align: center; }
  #custom-tools-table .color-cell .color-square {
    height: 25px; }

#ticket-cost-table th, #ticket-cost-table td {
  text-align: center; }

#ticket-cost-table td input {
  width: 75px;
  margin: auto; }

#quotes-table .modified small {
  color: blue; }

#quote .quote-copy {
  border: 1px solid black;
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto; }

/********* Portlet **********/
.portlet {
  clear: both;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px; }
  .portlet > .portlet-title {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee; }
    .portlet > .portlet-title:before, .portlet > .portlet-title:after {
      content: " ";
      display: table; }
    .portlet > .portlet-title:after {
      clear: both; }
    .portlet > .portlet-title > .caption {
      float: left;
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
      margin: 0;
      padding: 0;
      margin-bottom: 8px; }
      .portlet > .portlet-title > .caption > i {
        float: left;
        margin-top: 4px;
        display: inline-block;
        font-size: 13px;
        margin-right: 5px; }
    .portlet > .portlet-title > .actions {
      display: inline-block;
      padding: 0;
      margin: 0;
      margin-top: 6px;
      float: right; }
      .portlet > .portlet-title > .actions .btn-default {
        background: transparent !important;
        background-color: transparent !important;
        border: 1px solid #b7d1fc;
        color: #d0e1fd; }
        .portlet > .portlet-title > .actions .btn-default:hover {
          border: 1px solid #e8f0fe;
          color: white; }
      .portlet > .portlet-title > .actions > .btn {
        padding: 3px 8px;
        margin-top: -13px; }
  .portlet > .portlet-body {
    clear: both;
    padding: 0; }
  .portlet.box {
    padding: 0px !important; }
    .portlet.box > .portlet-title {
      margin-bottom: 0px;
      padding: 10px 10px 4px 10px;
      border-bottom: 1px solid #eee;
      color: #fff; }
    .portlet.box > .portlet-body {
      background-color: #fff;
      padding: 10px; }
    .portlet.box.blue {
      border: 1px solid #466c90;
      border-top: 0; }
      .portlet.box.blue > .portlet-title {
        background-color: #466C90; }
        .portlet.box.blue > .portlet-title > .caption {
          color: white; }
  .portlet.blue {
    background-color: #466C90; }

/********* Notes ************/
/********** Notes **********/
#notesWidgetWrap {
  border: 1px solid #dddddd;
  overflow: auto; }
  #notesWidgetWrap div {
    padding: 0; }
  #notesWidgetWrap .header-container {
    background-color: #dddddd; }
    #notesWidgetWrap .header-container span {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857;
      padding: 8px;
      text-align: left;
      vertical-align: bottom; }
  #notesWidgetWrap .body-container {
    border-right: 1px solid #dddddd;
    min-height: 170px; }
  #notesWidgetWrap .row-container {
    max-height: 195px;
    overflow: auto; }
    #notesWidgetWrap .row-container .editing-note {
      outline: 2px solid #f9e55a;
      outline-offset: -2px; }
  #notesWidgetWrap .info-bar {
    background-color: #f9f4d1;
    position: absolute;
    top: 0;
    height: 18%;
    overflow: auto; }
    #notesWidgetWrap .info-bar .delete-wrapper {
      height: 100%; }
      #notesWidgetWrap .info-bar .delete-wrapper a {
        display: block;
        margin: 0 auto;
        position: relative;
        float: right;
        margin: 8px;
        vertical-align: middle;
        width: 65px; }
        #notesWidgetWrap .info-bar .delete-wrapper a i {
          margin-right: 5px; }
      #notesWidgetWrap .info-bar .delete-wrapper .btn {
        color: #333333;
        background-color: #e5e5e5; }
        #notesWidgetWrap .info-bar .delete-wrapper .btn:hover {
          color: #333333;
          background-color: #d6d6d6; }
    #notesWidgetWrap .info-bar .date-author {
      position: relative;
      padding: 8px; }
  #notesWidgetWrap .new-note {
    color: #35aa47; }
    #notesWidgetWrap .new-note .date-column {
      color: #35aa47;
      font-weight: 800;
      text-align: center; }
  #notesWidgetWrap .edit-container {
    height: 100%;
    position: absolute;
    left: 50%;
    border-left: 1px solid #dddddd; }
    #notesWidgetWrap .edit-container textArea {
      visibility: hidden; }
    #notesWidgetWrap .edit-container .info-bar {
      display: none; }
    #notesWidgetWrap .edit-container.editing-note textArea {
      visibility: visible;
      background-color: #f7eda7;
      border: 0;
      resize: none; }
    #notesWidgetWrap .edit-container.editing-note .info-bar {
      display: block; }
    #notesWidgetWrap .edit-container .saved-msg {
      display: none;
      background-color: #dff0d8;
      border: 1px solid #35aa47;
      border-radius: 5px;
      bottom: 0;
      color: #35aa47;
      margin: 4px 8px;
      padding: 4px 6px;
      position: absolute;
      right: 0;
      z-index: 10; }
    #notesWidgetWrap .edit-container .failed-msg {
      display: none;
      background-color: #ffcccc;
      border: 1px solid #ff3333;
      border-radius: 5px;
      bottom: 0;
      color: #ff3333;
      margin: 4px 8px;
      padding: 4px 6px;
      position: absolute;
      right: 0;
      z-index: 10; }
  #notesWidgetWrap .note {
    border-bottom: 1px solid #dddddd;
    cursor: pointer; }
    #notesWidgetWrap .note div {
      line-height: 1.42857;
      padding: 8px;
      vertical-align: top; }
  #notesWidgetWrap .date-column {
    border-right: 1px solid #dddddd;
    overflow: auto; }
  #notesWidgetWrap .excerpt-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  #notesWidgetWrap .viewable-text {
    top: 18%;
    height: 82%;
    padding: 10px; }

#cost-breakdown-table, #cost-breakdown-table th, #cost-breakdown-table td {
  border: 1px solid #d3e0e9;
  border-collapse: collapse; }

#cost-breakdown-table th, #cost-breakdown-table td {
  padding: 10px; }

#cost-breakdown-table .emphasize {
  font-weight: 700;
  background-color: #f7f7f7; }

/*# sourceMappingURL=app.css.map */
